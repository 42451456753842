import { Link } from "react-router-dom"

interface Props {
  title: string,
  src: string,
  gradientColor: string[]
}

export const LandingPageCard = ({
  title,
  src,
  gradientColor
}: Props) => {
  return (
    <div 
      style = {{
        width: "295px",
        height: "240px",
        borderRadius: "20px",
        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
        margin: "20px"
      }}
    > 
      <Link to={`http://${src}`} style={{ textDecoration: 'none' }}>
        <div 
          style={{
            display: "flex",
            width: "100%",
            height: "165px",
            background: `linear-gradient(to right, ${gradientColor[0]}, ${gradientColor[1]})`,
            borderRadius: "20px 20px 0px 0px"
          }}
        >
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "75px",
            backgroundColor: "white",
            borderRadius: "0px 0px 20px 20px",
            alignContent: "flex-start",
            padding: "20px",
            boxSizing: "border-box"
          }}
        >
          <text
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "revert-layer",
              color: "black"
            }}
          >
            {title}
          </text>
        </div>
      </Link>
    </div>    
  )
}