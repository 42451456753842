import { LandingPageCard } from "../components/LandingPageCard";

export const HomePage = () => {
  const links = [
    { title: 'Wiki', src: 'wiki.quant-hub.com' },
    { title: 'Metrics', src: 'metrics.quant-hub.com' },
    { title: 'Code Repository', src: 'github.quant-hub.com' },
    { title: 'Quantitative Toolkit API', src: 'toolkit.quant-hub.com' },
    { title: 'Historical Database API', src: 'github.com/wheelfredie/historical_DataBase' },
  ];

  const gradientColors = [
    ["#000000", "#2234AE"],
    ["#000000", "#923CB5"],
    ["#000000", "#E84393"],
    ["#000000", "#feb47b"],
  ]

  return (
    <div className="App"
      style={{
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#1B2528",
        height: "100vh",
      }}
    >
      {links.map((link, index) => {
        const colorKey = index % 4;
        const gradientColor = gradientColors[colorKey];
        return (
          <LandingPageCard 
            key={index}
            title={link.title}
            src={link.src} 
            gradientColor={gradientColor}
          />
        );
      })}
    </div>
  );
};
